import React, { Component } from 'react';

import Car from "../components/car"
import Line from '../components/line'

import {___} from "../translate"

import {SubcategoryIcons, POSITION_FRONT, POSITION_BACK} from "../categories"









class Subcategory extends Component {

	constructor(props) {
		super(props);


		this.state = {
			carFront: true,
			carIsTurning: false,
			carContainerScale:1,
		};
		this.setCarIsTurning = this.setCarIsTurning.bind(this)


		this.updateDimensions = this.updateDimensions.bind(this)

	}


	updateDimensions(){


		let carContainerScaleX = window.innerWidth / 1024
		let carContainerScaleY = window.innerHeight / 768

		let carContainerScale = Math.min(carContainerScaleX, carContainerScaleY)
		this.setState({carContainerScale})

  };

  componentDidMount() {
		this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }



	setCarIsTurning(carIsTurning){
		this.setState({carIsTurning})

	}

	renderLines(){

		if(this.props.currentCategory.subcategory_buttons_on_car){
			// Buttons on car
			return this.props.currentCategory.subcategories.map((subcategory, index)=>{

				let currentPosition = this.state.carFront ? POSITION_FRONT : POSITION_BACK
				if(subcategory.position !==  currentPosition){
					return null;
				}

				return <Line key={index} x0={subcategory.line[0].x*this.state.carContainerScale}
							 y0={subcategory.line[0].y*this.state.carContainerScale}
							 x1={subcategory.line[1].x*this.state.carContainerScale}
							 y1={subcategory.line[1].y*this.state.carContainerScale}/>

			})

		}
		return null;
	}

	renderCarButtons(){

		// Buttons on car
		return this.props.currentCategory.subcategories.map((subcategory, index)=>{

			let currentPosition = this.state.carFront ? POSITION_FRONT : POSITION_BACK

			if(subcategory.position !==  currentPosition){
				return null;
			}

			return (<div
						key={index}
						onClick={()=>{this.props.selectSubcategory(subcategory)}}
						className="car-button"

						style={{left:subcategory.line[1].x*this.state.carContainerScale, top:subcategory.line[1].y*this.state.carContainerScale}}>
						<div className="icon">
							<img alt="" src={SubcategoryIcons[subcategory.icon]}/>
						</div>

				<div>{___(subcategory.name)}</div>
			</div>)
		})

	}
	renderPlainButtons(){

		return <div className="buttons">
			{
			this.props.currentCategory.subcategories.map((subcategory, index)=>{
				return (<button key={index} onClick={()=>{this.props.selectSubcategory(subcategory)}} className="btn btn-dark">
					{___(subcategory.name)}
				</button>)
				})
			}
		</div>
	}

	renderCar(){

		return (<div className="car-wrapper">

			<div className="car-container" style={{
				marginTop:-50*this.state.carContainerScale,
				width:1024*this.state.carContainerScale,
				height:768*this.state.carContainerScale

			}}>
				<div className="car-lines">
					{! this.state.carIsTurning && this.renderLines()}
				</div>

				<div className="car-buttons">
					{ ! this.state.carIsTurning && this.renderCarButtons()}
				</div>
				<Car style={{left:78*this.state.carContainerScale, top:205*this.state.carContainerScale, width: 880*this.state.carContainerScale, height:368*this.state.carContainerScale}} carFront={this.state.carFront} setCarIsTurning={this.setCarIsTurning}/>
			</div>
		</div>
	)

	}

	render() {
		return (
			<div className="subcategory">

				<div className="d-flex header">
					<h3>{this.props.currentCategory.subcategory_title}</h3>
					<div className="ml-auto">

					{ this.props.currentCategory.subcategory_buttons_on_car && this.props.currentCategory.subcategory_turn_car_back &&
						<button onClick={()=>this.setState({carFront:!this.state.carFront})} className='btn btn-dark' >
							{this.state.carFront
								? ___(this.props.currentCategory.subcategory_turn_car_back)
								: ___(this.props.currentCategory.subcategory_turn_car_front)
							}
						</button>
					}

					</div>
				</div>
				{ this.props.currentCategory.subcategory_buttons_on_car ?	this.renderCar() : this.renderPlainButtons() }

			</div>
		)
	}

}

export default Subcategory;
