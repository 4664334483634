
import React from 'react';
import axios from "axios"
import {MODE_APP, MODE_WEB, MODE_KIOSK, MODE_WEB_KIOSK} from "./constants"

import {parseHandofUrl, getDeviceIdAndToken} from "./utils/device"

import AppContext from "./context"
import service from "./service"

import {AppPages} from "./pages"
import {
  HashRouter as Router,
} from "react-router-dom";



const App = ({mode, data})=>{

  const [loading, setLoading] = React.useState(true)

  const [auth, setAuth] = React.useState({})
  const jwtToken = React.useRef()

  const [user, setUser] = React.useState()
  const [message, setMessage] = React.useState()

  React.useEffect(()=>{


    let intervalId;

    const loadHandOfToken = async()=>{

      const handOfSession = parseHandofUrl()
      if(! handOfSession){
        return
      }

      setLoading(true)

      try{
        let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/app-from-kiosk`, {token:handOfSession})

        const newAuth = {
          jwtToken: response.data.token,
          deviceId: response.data.device_id
        }
        setAuth(newAuth)

      }catch(er){
      }
      setLoading(false)

    }


    // const loadIpToken = async()=>{
    //
    //   try{
    //     let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/token-from-ip`)
    //
    //     const newAuth = {
    //       jwtToken: response.data.token,
    //       deviceId: response.data.device_id
    //     }
    //     setAuth(newAuth)
    //   }catch(er){
    //   }
    //
    //   setLoading(false)
    //
    // }

    const checkActivation = async()=>{

      const deviceId = localStorage.getItem("device_id")
      const deviceToken = localStorage.getItem("device_token")
      const activationUuid = localStorage.getItem("activation_uuid")


      if(!deviceId || !activationUuid ||!deviceToken){
        setLoading(false)
        clearInterval(intervalId)
        return
      }

      try{
        await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/check-activation`, {device_id: deviceId, activation_uuid: activationUuid, timestamp: new Date()})

        const newAuth = {
          deviceId: deviceId,
          deviceToken: deviceToken
        }
        setAuth(newAuth)
        setLoading(false)
        clearInterval(intervalId)

      }catch(er){
        if(er.response && er.response.status === 400  && er.response.data && er.response.data.reset){
          console.log("activation reset")
          localStorage.clear()

          setLoading(false)
          clearInterval(intervalId)
        }
      }

    }


    const cookieLogin = async ()=>{
      try{
        let response = await service.login({remember_token: true})

        if(response.data.success){

          let newAuth = {
            userToken: response.data.token,
            userId: response.data.user_id
          }
          setAuth(newAuth)
        }
      }catch(er){
      }
      setLoading(false)
    }

    if(mode === MODE_APP){
      // exhange token from qr code
      loadHandOfToken()

    }else if(mode === MODE_WEB){
      // Remember me cookie
      cookieLogin()

    }else if(mode=== MODE_WEB_KIOSK){

      intervalId = setInterval(checkActivation, 1000*60)
      checkActivation()

      return ()=>{
        clearInterval(intervalId)
      }

    }else if(mode === MODE_KIOSK){
      // From signage cms
      const {deviceId, deviceToken} = getDeviceIdAndToken(data)
      const newAuth = {
        deviceId: deviceId,
        deviceToken: deviceToken
      }
      setAuth(newAuth)
      setLoading(false)
    }

  }, [mode, data])



  // Load settings

  React.useEffect(()=>{

    const loadUser = async ()=>{
      let response = await service.get(auth, `/api/users/${auth.userId}`)
      setUser(response.data.user)
    }

    const loadDevice = async ()=>{
      let response = await service.get(auth, `/api/settings`)
      setUser(response.data)
    }

    if(auth.userId){
      loadUser()

    }else if(auth.deviceId){
      loadDevice()

    }else{
      setUser()

    }

  }, [auth])



  if(loading){
    return <div className="splash">
      <span className="spinner-border"/>
    </div>
  }


  if(mode === MODE_APP && ! auth.jwtToken){
    return <div className="splash">
      Scanna QR-kod i butiken för att fortsätta
    </div>
  }

  return <AppContext.Provider value={{mode, auth, setAuth, user, jwtToken}}>
      <Router>
        <AppPages />
      </Router>
    </AppContext.Provider>

}

export default App;
